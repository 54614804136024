@import './base.css';

.b-reset {
  border-color: none;
  border-width: 2px;
  border-style: solid;
}

textarea,
input[type="text"],
input[type="email"],
input[type="number"] {
  padding: 6px;
  width: 100%;
  outline: none;
}

textarea:focus::selection,
input[type="text"]:focus::selection,
input[type="email"]:focus::selection,
input[type="number"]:focus::selection {
  background-color: #4330FC;
  color: white;
}

.flex {
  flex-wrap: nowrap;
}

/*
CHECKBOX
*/
.checkbox p {
  user-select: none;
  margin-top: 0;
  margin-bottom: 0;
}

.checkbox p:first-child {
  font-weight: 600;
  margin-bottom: 2rem;
}

.checkbox p:first-child:hover {
  /* cursor: pointer; */
}

.checkbox p:nth-child(2) {
  font-weight: 400;
}

.checkbox div {
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
  flex: 1;
}

.checkbox input[type='checkbox'] {
  display: none;
}

.checkbox label:before {
  content: '';
  position: absolute;
  width: 8rem;
  height: 8rem;
}

.checkbox.isNotChecked label:before {
  border: 2px solid #333;
  background-color: transparent;
}

.checkbox.isChecked label:before {
  background-color: #4330FC;
  border: 2px solid #4330FC;
}

.checkbox label {
  width: 4rem;
  height: 4rem;
  margin:0;
  position: relative;
}

.checkbox label:hover {
  cursor: pointer;
}

.checkbox label input[type='checkbox']+svg {
  transform: translate(0, 0);
  opacity: 0;
}


.checkbox label input[type='checkbox']:checked+svg {
  opacity: 1;
}

.checkbox label input[type='checkbox']:checked+span {
  background-color: black;
}

.indicator {
  width: 2rem;
  height: 2rem;
  display: block;
  content: '';
  border-radius: 999px;
}

.br-pill {
  border-radius: 9999px;
}

.z-999 {
  z-index: 999;
}

.bts-solid {
  border-top-style: solid;
}

.down-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 4px 0 4px;
  border-color: white transparent transparent transparent;
}

button, .btn {
  font-size: 3.25rem;
  white-space: nowrap;
  padding: 4rem;
}

.flex-center-all {justify-content: center; align-items: center}
